import React, { useState } from "react"
import { Button } from "antd"
import { useTranslation } from "react-i18next"

import Layout from "../../components/Layout/LayoutPublic/LayoutPublic"
import { ModalNewLead } from '../../components/Modals/ModalNewLead/ModalNewLead'

const Revolutionize = ({
  data: { contentfulPaginaRevolucionaTusEntregas: contentfulPageRevolutionize },
}) => {
  const { t } = useTranslation()

  const [showModalNewLead, setShowModalNewLead] = useState(false)
  const [form, setForm] = useState()

  if (!contentfulPageRevolutionize) return null

  const {
    title,
    section_3,
    section_generators,
    section_operators,
  } = contentfulPageRevolutionize

  const cards = [
    { ...section_generators, formKey: 'form_generator' }, 
    { ...section_operators, formKey: 'form_operator' }
  ]

  const openModalNewLead = (openForm) => {
    setForm(openForm)
    setShowModalNewLead(true)
  }

  return (
    <Layout>
      <ModalNewLead
        handlerClose={(newState) => setShowModalNewLead(newState)}
        visible={showModalNewLead}
        keyForm={form}
      />

      <div className="Revolutionize">
        <div className="Revolutionize__container" data-aos="fade-up">
          <h1 className="title">{title}</h1>
          <div className="cards">
            {cards?.map((card, index) => (
              <div key={index} className="cards__card">
                <div>
                  <h4 className="cards__card--sub">{card.name}</h4>
                  <h1 className="cards__card--title">{card.title}</h1>
                  <p className="cards__card--text">
                    {card.description?.description}
                  </p>
                </div>
                <div className="cont-btn">
                  <Button className="btn btn--demo" onClick={() => openModalNewLead(card.formKey)}>
                    {t('button.signup_here')}
                </Button>
                </div>
              </div>
            ))}
          </div>
          <div className="iam-lifter">
            <div className="iam-lifter__card">
              <img src={section_3.image} alt="iam-lifter" className="lifter" />
              <div className="iam-lifter__card--text">
                <h2 className="iam-lifter__card--text--title">
                  {section_3.title}
                </h2>
                <div className="cont-btn">
                  <Button
                    className="btn btn--demo"
                    onClick={() => openModalNewLead('form_lifter')}
                  >
                    {t("button.want_lifter")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageRevolutionizesDeliveries($locale: String) {
    contentfulPaginaRevolucionaTusEntregas(
      language: { language: { eq: $locale } }
    ) {
      title
      section_generators {
        ...SectionFragment
      }
      section_operators {
        ...SectionFragment
      }
      section_3 {
        ...SectionFragment
      }
    }
  }
`

export default Revolutionize
